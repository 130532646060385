import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 分钟访问重复路由时报错
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  { path: '/', redirect: '/kd' },
  { path: '/flow', component: () => import('@/views/Flow') ,meta:{title:'云南联通流量卡'}},
  { path: '/cqflow', component: () => import('@/views/cqFlow'),meta:{title:'重庆流量宽带办理'},name:'cqFlow' },
  { path: '/cdflow', component: () => import('@/views/cdFlow'),meta:{title:'成都流量卡办理'} ,name:"cdFlow"},
  {path: '/flow/result', component: () => import('@/views/Flow/result'),meta:{title:'办理成功'} },
  {path: '/cqflow/result', component: () => import('@/views/cqFlow/result'),meta:{title:'办理成功'} },
  {path: '/cdflow/result', component: () => import('@/views/cdFlow/result'),meta:{title:'办理成功'} },
  { path: '/kd', component: () => import('@/views/Kd'),meta:{title:'云南宽带安装'} },
  { path: '/cdflow/kd', component: () => import('@/views/cdFlow/Kd'),meta:{title:'成都宽带安装'} },
  { path: '/cqflow/kd', component: () => import('@/views/cqFlow/kd'),meta:{title:'重庆宽带安装'} },
  { path: '/cqflow/cqly', component: () => import('@/views/cqFlow/cqly'),meta:{title:'重庆联通宽带在线预约'} },
]

const router = new VueRouter({
  routes,
})
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }
  const domain = window.location.hostname;
  //console.log(domain);

  if (domain === 'cd.km130.com'&& to.name!='cdFlow') {
    next({name: 'cdFlow'});
  } else if(domain === 'cq.km130.com'&& to.name!='cqFlow'){
    next({name: 'cqFlow'});
  }else{
    next();
  }
 
});
export default router
