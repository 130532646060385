<template>
  <div id="app">
    <keep-alive >
      <router-view  />
    </keep-alive>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isShow: false
    }
  },
  components: {
  
  }
}
</script>

<style lang="scss">
// html{font-size:10vw !important}

#app {
  width: 750px;
  // height: 86vh;
  // overflow-x: hidden;
  padding-top:2rem ;
  ::-webkit-scrollbar {
    display: none;
  }
  .bottom50 {
    z-index: 99;
    height: 120px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 100px;
    transition: all 0.3s ease-in;
  }
  .bottom0 {
    z-index: 99;
    height: 120px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    transition: all 0.3s ease-in;
  }
  .placeholder {
    width: 100px;
    height: 60px;
  }
}
@media screen and (min-width:720px){
  html{font-size:80PX}
  #app{width:8.5rem;margin: 0 auto;}
  }
</style>
